/* Remove Borders Around the Editor and Toolbar */
.ql-container,
.ql-toolbar {
    border: none !important;
}

/* Increase Text Size for Headings */
.ql-snow .ql-editor h1 {
    font-size: 2em; /* or your desired size */
}

.ql-snow .ql-editor h2 {
    font-size: 1.75em; /* or your desired size */
}

.ql-snow .ql-editor h3 {
    font-size: 1.5em; /* or your desired size */
}

.ql-snow .ql-editor p {
    font-size: 1.25em; /* or your desired size */
}

/* Increase the Size of Icons in the Toolbar */
.ql-snow .ql-toolbar button .ql-icon {
    font-size: 24px; /* or your desired size */
}
